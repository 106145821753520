.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}
.logo {
  float: left;
  width: 120px;
  margin-right: 40px;
  background: #fff;
}
.ant-row-rtl .logo {
  float: right;
  margin: 12px 0 16px 24px;
}
.ant-layout-header {
  background: #fff;
  border-bottom: 5px solid rgb(248, 152, 40);
  height: 94px !important;
}
.ant-layout-header .ant-menu {
  border-bottom: 5px solid rgb(248, 152, 40);
  height: 94px !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover {
  color: #005e9b !important;
}

.ant-menu-item-selected > .ant-menu-title-content {
  font-weight: bold;
}

.ant-menu-item {
  font-size: 18px;
  padding-top: 10px !important;
}
.ant-menu-submenu-title {
  font-size: 18px !important;
}

.ant-menu-overflow
  .ant-menu
  .ant-menu-root
  .ant-menu-horizontal
  .ant-menu-light {
  margin-right: 130px !important;
}

.ant-card {
  height: 100%;
}

@media (min-width: 780px) {
  .menubtn {
    margin-top: 20px !important;
    display: none !important;
  }
  .bigmenu {
    right: 10px !important;
  }
}

@media (max-width: 780px) {
  .bigmenu {
    display: none !important;
  }
  .menubtn {
    position: absolute !important;
    margin-top: 20px !important;
    right: 20px !important;
  }
}
.ant-drawer-title {
  text-align: center !important;
}
