@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  font-family: "Rubik", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-card-meta {
  display: block !important;
  text-align: center !important;
}

.ant-card-meta-title {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.ant-btn-primary {
  background-color: #005e9b !important;
  border-color: #005e9b !important;
}

.ant-list-item-meta-title {
  font-size: 16px !important;
  text-align: center !important;
}

.ant-card-head-title {
  text-align: center !important;
}

.ant-drawer-close {
  display: none !important;
}

.ant-card-meta-title {
  white-space: pre-line !important;
}

.leaflet-container {
  height: 300px;
}
